import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { AuthService } from '../auth.service';
import { AlertService } from './alert.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ExceptionService {

  constructor(
    private readonly authService: AuthService,
    private readonly alertService: AlertService,
    private readonly router: Router
  ) { }

  public catchBadResponse(error: HttpErrorResponse, urlRedirect: string = ''): Observable<any> {
    let errorMessage: string = '';
    let titulo: string = '';
    let subtitulo: string = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error del cliente: ${error.error.message}`;
    } else {
      errorMessage = `Error del servidor: ${error.status} - ${error.message}`;
    }
    switch (error.status) {
      case 401:
        if (this.authService.getToken()) {
          titulo = 'Su sesión ha expirado';
          subtitulo = 'Por favor vuelva a ingresar';
        } else {
          titulo = 'Disponible solo para usuarios registrados';
          subtitulo = 'Por favor ingrese con sus credenciales';
        }
        this.alertService.alertError(titulo, subtitulo, 'Ingresar').then(resp => {
          this.authService.logout();
          this.router.navigate(["/auth/ingreso"]);
        });
        break;
      default:
        this.alertService.alertError('Ups', 'Ocurrió un problema. Vuelve a intentar.').then(resp => {
          urlRedirect && this.router.navigate([`/${urlRedirect}`]);
        });
        break;
    }
    return throwError(errorMessage);
  }
}
