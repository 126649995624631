import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { ExceptionService } from './util/exception-request.service';
import { IContentArticles, IContentCarousel, IContentHome, IContentPlans } from '../interfaces';
import { AuthService } from './auth.service';
import { UtilsService } from './util/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CmsSeccionesService {

  private readonly homePath: string = `${environment.base_url}/crud-title/main-titles`;
  private readonly carouselPath: string = `${environment.base_url}/crud-carousel/carousels`;
  private readonly articlesPath: string = `${environment.base_url}/crud-article/articles`;
  private readonly plansPath: string = `${environment.base_url}/crud-memberships/membresias`;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly exceptionService: ExceptionService,
    private readonly authService: AuthService,
    private readonly utilsService: UtilsService
  ) { }

  public getContentHome(): Observable<any> {
    return this.httpClient.get(this.homePath).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public updateContentHome(contentHome: IContentHome): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.token}`
    });
    const { titulo } = contentHome;
    return this.httpClient.put(`${this.homePath}/${contentHome.id}`, { titulo }, { headers }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public getContentCarousel(queryParams?: any): Observable<any> {
    const params = this.utilsService.generateParams(queryParams);
    return this.httpClient.get(this.carouselPath, { params: params }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex))
    );
  }

  public createContentCarousel(slideCarousel: FormData): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.token}`
    });
    return this.httpClient.post(this.carouselPath, slideCarousel, { headers }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public updateContentCarousel(contentCarousel: FormData | IContentCarousel): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.token}`
    });
    let contentCarouselId: number | undefined = 0;
    if (contentCarousel instanceof FormData) {
      contentCarouselId = +(contentCarousel.get('id') as string);
      contentCarousel.delete('id');
    } else {
      contentCarouselId = contentCarousel.id;
      delete contentCarousel.id;
    }
    return this.httpClient.put(`${this.carouselPath}/${contentCarouselId}`, contentCarousel, { headers }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public deleteContentCarousel(slideCarouselId: number): Observable<void> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.token}`
    });
    return this.httpClient.delete<void>(`${this.carouselPath}/${slideCarouselId}`, { headers });
  }

  public getContentArticles(queryParams?: any): Observable<any> {
    const params = this.utilsService.generateParams(queryParams);
    return this.httpClient.get(this.articlesPath, { params: params }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public createContentArticle(article: IContentArticles): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.token}`
    });
    return this.httpClient.post(this.articlesPath, article, { headers }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public updateContentArticle(article: IContentArticles): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.token}`
    });
    return this.httpClient.put(`${this.articlesPath}/${article.id}`, article, { headers }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public deleteContentArticle(articleId: number): Observable<void> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.token}`
    });
    return this.httpClient.delete<void>(`${this.articlesPath}/${articleId}`, { headers });
  }

  public getContentPlans(queryParams?: any): Observable<any> {
    const params = this.utilsService.generateParams(queryParams);
    return this.httpClient.get(this.plansPath, { params: params }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public createContentPlan(plan: FormData): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.token}`
    });
    return this.httpClient.post(this.plansPath, plan, { headers }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public updateContentPlan(plan: FormData | IContentPlans): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.token}`
    });
    let planId: number | undefined = 0;
    if (plan instanceof FormData) {
      planId = +(plan.get('id') as string);
      plan.delete('id');
    } else {
      planId = plan.id;
      delete plan.id;
    }
    return this.httpClient.put(`${this.plansPath}/${planId}`, plan, { headers }).pipe(
      catchError(ex => this.exceptionService.catchBadResponse(ex)));
  }

  public deleteContentPlans(planId: number): Observable<void> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authService.token}`
    });
    return this.httpClient.delete<void>(`${this.plansPath}/${planId}`, { headers });
  }
}
